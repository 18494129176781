import React, { useEffect, useState } from 'react';
import { Carousel, Image, Modal } from 'react-bootstrap-v5';
import {
  BASE_URL,
  GET_SITESETTINGS,
  GET_SLIDERSETTINGS,
} from '../../constant/constants';

const Hero = () => {
  const [contents, setContents] = useState([]);
  const [show, setShow] = useState(false);
  const [modalImage, setModalImage] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    fetch(`${GET_SLIDERSETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.homepage_sliders);
      });

    handleShow();

    fetch(GET_SITESETTINGS)
      .then((response) => response.json())
      .then((data) => {
        setModalImage(data.general_settings[0]);
      })
      .catch(() => {});

    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const imageHeight = windowWidth < 600 ? 'auto' : 'auto';

  return (
    <>
      {modalImage?.slider && (
        <Modal size='lg' show={show} centered onHide={handleClose}>
          <Image
            fluid={true}
            thumbnail={true}
            rounded={true}
            style={{
              filter: 'brightness(80%)',
            }}
            src={`${BASE_URL}${modalImage?.slider}`}
            alt='First slide'
          />
        </Modal>
      )}
      <Carousel fade>
        {contents.map((n, index) => (
          <Carousel.Item key={index} interval={5000}>
            <img
              className='d-block w-100'
              style={{  height: imageHeight }}
              src={`${BASE_URL}${n.image}`}
              alt={`Slide ${index + 1}`}
            />
            {/* <Carousel.Caption
              style={{
                bottom: '0',
                top: '0',
                left: '0',
                right: '0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}>
              {n.title &&
                windowWidth >
                  500 &&(
                    <Interweave
                      allowAttributes
                      allowElements
                      disableLineBreaks={false}
                      content={n.title}
                      containerTagName='div'
                      style={{
                        fontSize: windowWidth < 600 ? '1' : '2rem',
                        fontWeight: 'bold',
                        color: 'white',
                      }}
                    />
                  )}
              {n.details && windowWidth > 500 && (
                <Link to={n?.link}>
                  <Button
                    style={{
                      fontWeight: '700',
                      color: 'white',
                      fontSize: windowWidth < 600 ? '1rem' : '1.25rem',
                      marginTop: '20px',
                      padding: '10px 20px',
                      border: 'none',
                      backgroundColor: 'rgb(255, 188, 19)',
                    }}>
                    {n.details || 'Our Portfolio'}
                  </Button>
                </Link>
              )}
            </Carousel.Caption> */}
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

export default Hero;
