import React, { useEffect, useState } from "react";
import {
  BASE_URL,
  GET_EMPLOYEES_WITHOUT_PAGINATION,
} from "../../constant/constants";

const OurEmployees = () => {
  const [employees, setEmpoyees] = useState([]);

  //for content
  useEffect(() => {
    fetch(`${GET_EMPLOYEES_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setEmpoyees(data.employees);
      })
      .catch(() => {});
  }, []);
  //console.log("employees", employees);

  return (
    <section className="wpo-features-section-s6 section-padding">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-6">{/* Title Section */}</div>
    </div>
    <div className="row d-flex align-items-stretch">
      {employees.map((employee) => (
        <div key={employee.id} className="col col-xl-4 col-lg-4 col-sm-6 col-12 d-flex">
          <div
            className="wpo-features-item d-flex flex-column text-center"
            style={{
              margin: "10px",
              padding: "20px",
              borderRadius: "20px",
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              minHeight: "300px", // Ensures equal height
            }}
          >
            <div>
              <img
                style={{
                  borderRadius: "50%",
                  display: "block",
                  width: "130px",
                  height: "130px",
                  margin: "0 auto",
                }}
                src={`${BASE_URL}${employee.image}`}
                alt=""
              />
            </div>
            <div>
              <h4 style={{ marginTop: "20px" }}>
                {employee.first_name} {employee.last_name}
              </h4>
              <h6 className="text-xs">{employee?.designation?.name}</h6>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>

  );
};

export default OurEmployees;
