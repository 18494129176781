import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';
import { BASE_URL, GET_IMAGE_BY_MENU_ID } from '../../constant/constants';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Gallery = (props) => {
  const [contentsImage, setContentsImage] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
      })
      .catch(() => {});
  }, [id, props.id]);

  const images = Object.entries(contentsImage)
    .map(([key, value]) =>
      key === 'Gallery' ? value.map((n) => `${BASE_URL}/media/${n}`) : []
    )
    .flat();

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  // Use media queries to determine the number of columns
  const isMobile = useMediaQuery('(max-width:600px)');
  const numCols = isMobile ? 1 : 3;

  return (
    <>
      <ImageList
        sx={{ width: '100%' }}
        cols={numCols}
        rowHeight={500}
        style={{ margin: '50px 0' }}>
        {images.map((item, index) => (
          <ImageListItem key={index} gap={8}>
            <img
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              src={`${item}`}
              srcSet={`${item}`}
              alt=''
              onClick={() => openImageViewer(index)}
              loading='lazy'
            />
          </ImageListItem>
        ))}
      </ImageList>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

export default Gallery;
